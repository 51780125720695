.section-box-parameters {
    background: white;
    //background: transparentize($ps-color, 1);
    //background: scale-color($ps-color, $lightness: 85%);
    height: fit-content;
    padding: 20px 0px 20px 0px;

    h2 {
        color: $ps-color;

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 5%);
        font-weight: 100;
    }

    .item {
        height: 100%;
        margin: 20px 10% 50px;
        text-align: center;
        padding: 0 10%;
        border-bottom: 1px solid #cacaca;
        display: flex;
        flex-direction: column;
        justify-content: space-around;


        .t {
            color: #da291c;
            margin: 10px 0;
            font-family: Impact;
            font-style: italic;
            font-size: 1.25rem;
        }

        .d {
            line-height: 22px;
            height: 66px;
            margin-bottom: 25px;
            font-weight: 100;
        }


    }

    .param-pic {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(small only) {
            margin-top: 30px;
        }
    }


}

.imgZoom img,
.imgY180 img {
    -moz-transition: all .8s ease 0s;
    -ms-transition: all .8s ease 0s;
    -o-transition: all .8s ease 0s;
    -webkit-transition: all .8s ease 0s;
    transition: all .8s ease 0s;
}

.imgZoom {
    overflow: hidden;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.imgZoom:hover img {
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}
