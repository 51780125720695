.power-config-frame {
    position: relative;
}

.section-box-configx {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: rgba(29, 14, 2, 0.8);
    padding: 20px 0px;

    h2 {
        color: scale-color($ps-color, $lightness: 95%); //$ps-color;

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 85%);
        font-weight: 100;
    }

    @include breakpoint(small only) {
        position: relative;
    }


}

.section-box-config {
    //background: $ps-color;
    background: scale-color($ps-color, $lightness: 5%);
    height: fit-content;
    padding: 20px 0px 20px 0px;

    h2 {
        color: scale-color($ps-color, $lightness: 95%); //$ps-color;

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 85%);
        font-weight: 100;
    }

}

.wrap {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    min-width: 1300px;
}



.section-box-config_tit .tit,
.pro_list14_tit .tit {
    text-align: center;
    padding: 100px 0 30px;
    margin: 0 auto;
    color: #000;
    max-width: 870px;

}

.pro_list14_tit .tit {
    padding: 85px 0 50px;
}

.section-box-config_tit .tit .t,
.pro_list14_tit .tit .t {
    font-size: 30px;
    margin-bottom: 15px;
}

.section-box-config {
    //height: 1280px;
}

//.section-box-config .con {
//    padding-top: 120px;
//    color: #fff;
//    position: relative;
//    z-index: 10;
//}
//
//.section-box-config .con .tit {
//    margin-left: 200px;
//    font-size: 40px;
//}
//
//.section-box-config .con .desc {
//    margin-left: 200px;
//    font-size: 14px;
//    width: 400px;
//    margin-top: 20px;
//}

.section-box-config .list {
    height: 100vh;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
}

.section-box-config .list .img_box {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1920px;
    margin-left: -960px;
    height: 100%;
    z-index: 1;
}

.section-box-config .list .img_box img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -960px;
    margin-top: -480px;
    opacity: 0;
    visibility: hidden;
    width: 1920px;
    height: 960px;
}

.section-box-config .list .img_box img.cur {
    opacity: 1;
    visibility: visible;
}

.section-box-config .list .video_cover {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.9)
}

.section-box-config .list .detail {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    color: #fff;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.section-box-config .list .detail .item {
    float: left;
    width: 25%;
    position: relative;
    cursor: pointer;
    color: #fff;
}

.section-box-config .list .detail .item .info {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    height: 100vh;
    position: relative;
}

.section-box-config .list .detail .item:first-child .info {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.section-box-config .list .detail .item .info .tit {
    font-size: 20px;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    padding: 0 40px;
    z-index: 1;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

.section-box-config .list .detail .item .info .cover {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: -100px;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    opacity: 0;
    visibility: hidden;
}

.section-box-config .list .detail .item .info .cover .t {
    font-size: 30px;
    line-height: 1.2;
    margin: 15px 0;
}

.section-box-config .list .detail .item .info .cover .d {
    font-size: 13px;
}

.section-box-config.fixed .list {
    position: fixed;
}

.section-box-config.abs .list {
    bottom: 0;
    top: auto;
}

.section-box-config .list .detail .item:hover .cover {
    opacity: 1;
    visibility: visible;
    bottom: 100px;
    padding-top: 40px;
}

.section-box-config .list .detail .item:hover .tit {
    opacity: 0;
    visibility: hidden;
    bottom: 20px;
}

.section-box-config .list .img_box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin-left: 0;
    height: 100%;
    z-index: 1;
}

.section-box-config .list .img_box img {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: auto;
}

.section-box-config .list .detail .item .info {
    height: 1280px;
}

.section-box-config .list .detail .item {
    //width: 33.333%;
}

//This is for small screen only
.section-box-config .item .cover .t {
    //font-size: 20px;
    //position: absolute;
    //bottom: 100px;
    //left: 0;
    //right: 0;
    //padding: 0 40px;
    //z-index: 1;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;

    @include breakpoint(small only) {
        color: scale-color($ps-color, $lightness: 95%);
        font-size: 1.1rem;
        font-weight: 500;
    }

}

.tbox {
    @include breakpoint(small only) {
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;

        .t {
            padding-right: 10px;
        }
    }

}

.cover .d {
    @include breakpoint(small only) {
        color: scale-color($ps-color, $lightness: 85%);
        font-weight: 100;
        margin-bottom: 20px;
        padding-left: 20px;
    }
}
