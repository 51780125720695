.section-box-smart {
    background: transparentize($ps-color, 0.7);
}

.wrap-smart {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    min-width: 1300px;

    @include breakpoint(small only) {
        min-width: 80%;
    }
}


.pro_list05 {
    position: relative;
    height: 1120px;
    overflow: hidden;
}

.pro_list05 .media {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -10%;
}

.pro_list05 .media img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

.pro_list05 .media img.cur {
    opacity: 1;
    visibility: visible;
}

/*.pro_list05 .media:after{content:""; position:absolute; width:100%; height:100%; top:0; left:0; background:url(../img/pro_list05_cover.png);}*/
.pro_list05 > img {
    position: absolute;
    left: 50%;
    margin-left: -2000px;
    max-width: none;
    width: 4000px;
    margin-top: 120px;
    bottom: 0;
}

.pro_list05 .tit {
    position: absolute;
    left: 0;
    right: 0;
    top: 150px;
    text-align: center;
    color: #fff;
}

.pro_list05 .tit .t {
    font-size: 30px;
    margin-bottom: 10px;
}

.pro_list05 .tit .desc {
    font-size: 13px;
}

.pro_list05 .tit .icon {
    margin-top: 15px;
}

.pro_list05 .tit .ctrl {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-size: 15px;
    margin-top: 40px;
    position: relative;
}

.pro_list05 .tit .ctrl:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 28px;
    right: 28px;
    height: 1px;
    background: rgba(255, 255, 255, 0.25);
}

.pro_list05 .tit .ctrl .item {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 28px;
    cursor: pointer;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    padding-bottom: 20px;
    position: relative;
}

.pro_list05 .tit .ctrl .item.cur {
    font-weight: bold;
}

.pro_list05 .tit .ctrl .item:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    right: 50%;
    height: 2px;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    background: #da291c;
}

.pro_list05 .tit .ctrl .item.cur:after {
    left: 0;
    right: 0;
}

.pro_list05 .tit .ctrl .item img {
    display: block;
    width: 70px;
    margin: 0 auto 20px;
}

.pro_list05 .num {
    font-family: 'leslieb';
    position: absolute;
    left: 50%;
    margin-left: -75px;
    width: 200px;
    bottom: 340px;
    z-index: 1;
    text-align: center;
    font-size: 36px;
    color: #1a191b;
}


//small screen only
@include breakpoint(small only) {
    .pro_list05 {
        height: 920px;
    }

    .pro_list05 .tit {
        top: 100px;
    }

    .pro_list05 > img {
        margin-top: 0px;
        width: 1500px;
        margin-left: -750px;
    }

    .pro_list05 .media {
        top: 0;
    }

    .pro_list05 .num {
        font-size: 22px;
        bottom: 130px;
    }

    .pro_list05 .tit {
        top: 40px;
    }

    .pro_list05 .tit .t {
        font-size: 22px;
    }

    .pro_list05 .tit .ctrl .item {
        margin: 0 10px;
    }

    .pro_list05 .tit .ctrl:after {
        left: 10px;
        right: 10px;
    }

    .pro_list05 {
        height: 460px;
    }

    .pro_list05 {
        height: 500px;
    }

    .pro_list05 .tit .ctrl .item img {
        width: 32px;
        margin-bottom: 10px;
    }

    .pro_list05 .tit .ctrl {
        margin-top: 10px;
    }

    .pro_list05 .num {
        margin-left: -85px;
        bottom: 122px;
    }

    .pro_list05 .media img {
        width: 200%;
        max-width: none;
        left: -50%;
    }

    .pro_list05 .num {
        font-size: 16px;
        margin-left: -89px;
        bottom: 128px;
    }


}
