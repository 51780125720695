.section-box-details {
    background: transparentize($ps-color, 0.7);
    //background: scale-color($ps-color, $lightness: 85%);
    height: fit-content;
    padding: 50px 0px 100px 0px;

    h3 {
        color: lighten($ps-color, 75%);

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 5%);
        font-weight: 100;
    }
}

.ps-accordion {

    .accordion-item {

        .accordion-content {
            padding-top: 0;
            padding-bottom: 0;

            @include breakpoint(small only) {
                padding: 0rem;
            }
        }

        &:first-child > :first-child {
            border-radius: 0;
        }

        &:last-child:not(.is-active) > .accordion-title {
            //border-bottom: 1px solid #e6e6e6;
            border-radius: 0;
        }

        &:last-child > :last-child {
            border-radius: 0;
        }

        .accordion-title {
            font-size: 1rem;
            font-weight: 700;
            color: $ps-color;
            //border: none;
            border-top: 1px solid #e6e6e6;
            border-bottom: none;

            &:hover,
            &:focus {
                background: transparentize($ps-color, 0); //#e6e6e6;
                color: white;
            }

        }

    }

    .accordion-item.is-active {
        background: transparentize($ps-color, 0); //#e6e6e6;
        color: white;

        .accordion-title {
            color: white;
        }

    }

}

.acc-frame {
    ul {
        list-style: none;

        @include breakpoint(small only) {
            margin-left: 0;
        }

        .key {
            display: grid;
            grid-template-columns: 1fr 2fr;
            line-height: 200%;
            border-top: 1px solid #c5c5c5;
            //color: #000;
            font-size: .9rem;
            transition: .5s ease;
            cursor: pointer;

            @include breakpoint(small only) {
                grid-template-columns: 40% 60%;
                line-height: 150%;
                font-size: .8rem;
            }

            .left,
            .right {
                @include breakpoint(small only) {
                    padding: 5px 0px;
                }
            }

            .right {
                font-weight: 100;

                @include breakpoint(small only) {
                    padding-left: 10px;
                }
            }

            .left {
                padding-left: 15px;
                transition: .5s ease;

                &:before {
                    content: "»";
                    color: grey;
                    position: relative;
                    left: -15px;
                    transition: .5s ease;

                    @include breakpoint(small only) {
                        left: -8px;
                        //padding-left: 16px;
                        //text-indent: -16px;
                    }

                }

                @include breakpoint(small only) {
                    padding-left: 20px;
                    text-indent: -10px;
                }

                &:hover,
                &:focus {
                    padding-left: 20px;

                    @include breakpoint(small only) {
                        padding-left: 30px;
                        text-indent: -10px;
                    }

                    &:before {
                        content: "»";
                        color: red;
                        position: relative;
                        left: -3px;
                        //transition: .5s ease;
                    }

                }

            }
        }

    }
}
