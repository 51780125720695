$expand-column-transition: all 0.5s ease-in-out;
$expand-column-background-color: #2c3840;
$expand-column-hover-width: 70%;
$expand-column-fluid: true;

.row {
    max-width: 95rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    //flex-flow: row wrap;
    background: grey;

    @include breakpoint(small only) {
        flex-direction: column;
    }
}


.expand-column-wrapper {
    height: 100vh;

    @if $expand-column-fluid=='true' {
        max-width: none;
    }

    @else {
        max-width: $global-width;
    }

    .column {
        padding: 1rem;
        transition: $expand-column-transition;
        display: flex;
        flex-basis: 0;
        align-items: center;
        justify-content: center;

        &:hover {
            flex-basis: $expand-column-hover-width;
        }

        &:hover {
            .expand-column-content {
                opacity: 1;
            }
        }
    }

    .expand-column-content {
        color: $white;
        font-weight: bold;
        opacity: 0;
        transition: $expand-column-transition;
    }

    @include breakpoint(small only) {
        flex-direction: column;
    }
}


// optional theming
// .expand-column-wrapper .column {
//   &:nth-of-type(1) {
//     background: lighten($expand-column-background-color, 25%);
//   }

//   &:nth-of-type(2) {
//     background: lighten($expand-column-background-color, 20%);
//   }

//   &:nth-of-type(3) {
//     background: lighten($expand-column-background-color, 15%);
//   }

//   &:nth-of-type(4) {
//     background: lighten($expand-column-background-color, 10%);
//   }

//   &:nth-of-type(5) {
//     background: lighten($expand-column-background-color, 5%);
//   }

//   &:nth-of-type(6) {
//     background: $expand-column-background-color;
//   }
// }

.expand-column-wrapper .column {
    &:nth-of-type(1) {
        background: url('../../assets/powersports-img/snarler-light-on.jpg') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }

    &:nth-of-type(2) {
        background: url('../../assets/powersports-img/snarler-robust.jpg') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }

    &:nth-of-type(3) {
        background: url('../../assets/powersports-img/snarler-pounce.jpg') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }

    &:nth-of-type(4) {
        background: url('https://images.pexels.com/photos/24248/pexels-photo.jpg?w=940&h=650&auto=compress&cs=tinysrgb') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }

    &:nth-of-type(5) {
        background: url('https://images.pexels.com/photos/41951/solar-system-emergence-spitzer-telescope-telescope-41951.jpeg?w=940&h=650&auto=compress&cs=tinysrgb') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }

    &:nth-of-type(6) {
        background: url('https://images.pexels.com/photos/2156/sky-earth-space-working.jpg') center center no-repeat;
        height: 100vh;
        background-size: cover;
    }
}
