//--- Main page section 2 styling

.boxbg {
    //background-color: adjust-hue($ps-color, 90);
    background-color: mix($ps-color, white, 80);
    //position: relative;
    //top: 100vh;

}

.marketing-site-content-section {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 20px 0;

    .button.round {
        border-radius: 5000px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .marketing-site-content-section-img {
        align-self: center;
        flex: 1 0 100%;

        img {
            width: 100%;
        }

        @media screen and (min-width: 40em) {
            flex: 1 0 50%;
        }
    }

    .marketing-site-content-section-block {
        padding: 1rem;
        flex: 1 0 100%;
        align-self: stretch;
        background: $light-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 40em) {
            flex: 1 0 50%;
            padding: 2rem;
        }
    }
}

.marketing-site-content-section-block-header {
    font-size: 2rem;
    color: $ps-color;
    font-weight: bold;
}

.marketing-site-content-section-block-subheader {
    font-size: 1rem;
    color: $ps-color;
    margin-bottom: 20px;
}
