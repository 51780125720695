//This is styling for ps-snarler.html
//The video div as bg for section #second
.screenbox {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background: grey;


    &:after {
        content: ""; // ::before and ::after both require content
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(70deg, #ee7f44, #33d0ff);
        opacity: .8;
        z-index: 2;
    }


    .bgclip {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 1;


    }
}


//for about pg
.aboutbox {
    position: relative;
    margin-top: 60px;
    padding: 20px 30px 20px 30px;
    //display: flex;
    flex-direction: column;
    align-items: center;
    //overflow: visible;

    @include breakpoint(small only) {
        //height: 100vh;
        margin-top: 60px;

    }

    p {
        font-weight: 100;
    }

    img {
        //width: 100%;
        padding-bottom: 20px;

    }

    .image {
        flex-shrink: 0;
        text-align: center;
        //flex-grow: 0;
    }

    h4,
    h5 {
        margin: 10px 0px 10px 0px;
        text-align: center;
    }

    cite {
        color: $ps-color;
    }
}

.contactbox {
    position: relative;
    margin-top: 0px;
    padding: 20px 30px 20px 30px;
    //display: flex;
    flex-direction: column;
    align-items: center;
    //overflow: visible;

    h4 {
        text-align: center;
    }

    p,
    li {
        font-weight: 100;
    }
}

.responsive-embed {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    iframe {
        border-radius: 10%;
    }
}


//The container for the orbit

#zero {
    padding-top: 10px;
}

.orbit-frame {
    position: relative;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    //height: 300vh;
    //height: fit-content;
    //height: fit-content;
    padding-top: 155px;
    padding-bottom: 50px;
    background: white;

    .bg0 {
        //position: static;
        background: white;

        @include breakpoint(small) {
            margin-bottom: 0px;
        }
    }

    .bg1 {
        //position: static;
        background: $ps-color;
        padding: 50px 0px 50px 0px;
        margin-top: 0px;
        //height: fit-content;
    }



    @include breakpoint(small only) {
        padding-top: 155px;
        margin-bottom: 0px;
    }
}



//model feature menu styling for bigger screen
.model-nav {
    position: fixed;
    width: 75vw;
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    top: 110px;
    z-index: 12;
    background: white;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    border-radius: 15px;
    border: solid 2px $ps-color;
    align-items: center;

    .logo-txt {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;

        @include breakpoint(small only) {
            grid-template-columns: 1fr;
            padding: 0px;
            width: 90%;
            justify-items: center;
        }

    }

    a {
        color: $ps-color;
        font-size: .9rem;
        //padding-top: 0.5rem;
    }

    .feature-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .quality-butt {
        @include breakpoint(small only) {
            font-size: .7rem;
            line-height: 120%;
            border-radius: 20px;
        }
    }


    @include breakpoint(small only) {
        width: 95vw;
        padding: 5px;
        //border: solid 1px lighten($ps-color, 12%);
        border: solid 1px fade-out($ps-color, .5);
        grid-template-columns: 1.5fr .5fr;
    }


}



//off-canvas menu box styling -dedicated for mobile
.oc-box {
    border-radius: 0px 0px 50% 0px;
    background: rgba(199, 192, 192, 0.95);
    //background: lighten($ps-color, 90%);
}

.oc-menu {
    padding-top: 70px;

    a {
        color: $ps-color;
        padding-top: 0.5rem;
    }
}

img {}

h4 {
    margin-bottom: 0;
    margin-left: 20px;
    font-weight: bolder;
    color: $ps-color;
}

//}

.ps-tabs {

    .tabs-title {
        a {
            color: $ps-color;
        }
    }
}

.slide-frame {
    display: flex;
    align-items: center;
    justify-content: center;

    .orbit-image {
        width: 100%;
        max-width: 60%;
        margin: 0;

        @include breakpoint(small only) {
            max-width: 95%;
        }

    }
}

.ps-orbit {
    .orbit-bullets {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        text-align: center;
    }
}

.quality-butt {
    //position: absolute;
    //top: 10px;
    //right: 10px;
    //z-index: 0;
}

#ps-tabs {

    .accordion-item:first-child > :first-child,
    .accordion-item:last-child > :last-child,
    :last-child:not(.is-active) > .accordion-title {
        border-radius: 0px 0px 0px 0px;
    }

}

.section-box {
    height: fit-content;

    section {

        margin: 10px 0px 10px 0px;
        //background: lighten($ps-color, 80%);
        //background: scale(rgba($ps-color, 0.7), $alpha: 30%);
        //background: scale-color($ps-color, $lightness:100%, $alpha: 0);
        background: mix($ps-color, white, 30);
        //background: fade-out(lighten($ps-color, 100%), 0.1);
        border-radius: 20px;
        padding: 20px;


        h3 {
            font-weight: bolder;
            font-size: 3rem;

        }

    }
}

.control-box {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: start;
    //background: url(../../assets/powersports-img/snarler-light-on.jpg) center center no-repeat;
    //background-size: cover;
    height: 55vh;
    margin-bottom: 20px;


    h5 {
        font-size: 2.5rem;
        color: grey;
        margin-left: 20px;
        grid-column: 1;
        grid-row: 1 / span 2;
        //grid-column-end: 5;
    }

    p {
        grid-column: 3;
        grid-row: 4/ span 2;
        color: white;
        text-align: right;
        margin-right: 20px;
    }

}

.cb-bg1 {
    background: url(../../assets/powersports-img/snarler-light-on.jpg) center center no-repeat;
    background-size: cover;
    height: 55vh;
}

.cb-bg2 {
    background: url(../../assets/powersports-img/snarler-robust.jpg) center center no-repeat;
    background-size: cover;
    height: 55vh;
}

.cb-bg3 {
    background: url(../../assets/powersports-img/snarler-pounce.jpg) center center no-repeat;
    background-size: cover;
    height: 55vh;
}


.ps-pg-content {
    //position: relative;
    //height: 200vh;
    //overflow-y: scroll;
}



///the video box
.video-box {
    position: relative;
    z-index: -1;
    //overflow: hidden;
    margin: 0px 0px;
    //height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    //    &:after {
    //        content: ""; // ::before and ::after both require content
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        width: 100%;
    //        height: 100%;
    //        background-image: linear-gradient(70deg, #eaee44, #33d0ff);
    //        opacity: .5;
    //        z-index: 1;
    //    }

    .video {
        min-width: 100%;
        min-height: 100%;
        object-fit: fill;
        height: 100vh;
        top: 0;
        left: 0;
        position: absolute;

        //z-index: 1;



        video {
            min-width: 100%;
            min-height: 100%;
            object-fit: fill;
            width: 100%;
            //margin-top: -70px;
            //margin-bottom: -80px;

            &:after {
                content: ""; // ::before and ::after both require content
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(70deg, #eaee44, #33d0ff);
                opacity: .7;
                z-index: 1;
            }
        }
    }

    .pic {
        position: relative;
        z-index: 10;
        text-align: center;
        color: white;
        padding: 50px 0px 10px 0px;

        h2 {
            font-weight: bold;
        }
    }

    .dynamic {
        position: relative;
        z-index: 11;
    }

    .dual-oil {
        position: relative;
        z-index: 12;
    }

    .cool {
        position: relative;
        z-index: 13;
    }

    .dynamic,
    .dual-oil,
    .cool {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 70%;
            margin: 10px 0px;
            border-radius: 20px;
        }

        h4 {
            margin: 10px 0px;
        }

        p {
            color: $ps-color;
        }
    }
}

//.pro_list03:after {
//    content: "";
//    position: fixed;
//    left: 0;
//    top: 0;
//    right: 0;
//    bottom: 0;
//    background: rgba(0, 0, 0, 0.7);
//    z-index: 15;
//}


.dynamic,
.dual-oil,
.cool {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 70%;
        margin: 10px 0px;
        border-radius: 20px;
    }

    h4 {
        font-size: 1.25rem;
        margin: 0px 0px 20px 0px;
        text-align: center;
    }

    p {
        color: $ps-color;
        text-align: center;
    }
}


.main-txt {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px 20px 20px 20px;


}

.snarl-butt {
    position: relative;
    z-index: 4;
    width: 300px;
    margin: 0 auto;

    .img1 {
        vertical-align: top;
        border: 0;
        border-radius: 20px;
    }

    .start {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        margin-left: -132px;
        margin-top: -132px;
        //opacity: 1;
        //visibility: hidden;
        //-moz-transition: all .8s ease 0s;
        //-ms-transition: all .8s ease 0s;
        //-o-transition: all .8s ease 0s;
        //-webkit-transition: all .8s ease 0s;
        transition: all .8s ease 0s;
    }

    .stop {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 4;
        margin-left: -132px;
        margin-top: -132px;
        //opacity: 1;
        //visibility: hidden;
        //-moz-transition: all .8s ease 0s;
        //-ms-transition: all .8s ease 0s;
        //-o-transition: all .8s ease 0s;
        //-webkit-transition: all .8s ease 0s;
        transition: all .8s ease 0s;

    }
}

.snarl-txt {
    position: relative;
    //color: white;
    z-index: 4;
    width: 50vw;
    margin: 20px auto;
}
