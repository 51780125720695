//styling for snarler control-tension

.section-box-tension {
    background: $ps-color;
    position: relative;
    //height: 960px;
    //z-index: 10;
    //height: fit-content;
    //padding: 20px 0px 20px 0px;


    .pic {
        position: absolute;
        //left: 50%;
        //top: 0;
        width: 100%;
        //margin-left: -960px;
        //height: 960px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.9s ease 0s;
            max-width: 100%;
            height: auto;
            vertical-align: top;
            -ms-interpolation-mode: bicubic;
            border: 0;
        }

        img.cur {
            opacity: 1;
            visibility: visible;
        }
    }

    .tit {
        text-align: center;
        margin: 0px auto;
        padding: 20px;
        color: #fff;
        width: 100%;
        position: absolute;
        top: 0;
        //left: 0;
        z-index: 11;

        @include breakpoint(small only) {
            padding: 5px;
        }

        .light {
            margin-top: 10px;
            //position:relative;
            //right: 0%;
            text-align: center;
            cursor: pointer;

            .light_con {
                position: relative;
                display: inline-block;
                //display: inline;
                //zoom: 1;
                height: 28px;
            }

            .light_line {
                width: 92px;
                height: 28px;
                background: url(../powersports-img/ori-name/close_light.png);
                margin: 0 auto;
                display: inline-block;
                *display: inline;
                *zoom: 1;
                transition: all 0.5s ease 0s;
            }

            .light_ball {
                position: absolute;
                width: 60px;
                height: 60px;
                top: 50%;
                margin-top: -30px;
                right: 100%;
                margin-right: -40px;
                background: url(../powersports-img/ori-name/ball_light.png);
                transition: all 0.5s ease 0s;
            }

        }

        .light.open {
            .light_line {
                background: url(../powersports-img/ori-name/open_light.png);
            }

            .light_ball {
                right: 0;
                margin-right: -20px;
            }
        }



    }

    .btm {
        position: relative;
        left: 0;
        right: 0;
        //bottom: 120px;

        .item {
            position: relative;
            color: #fff;
            cursor: pointer;
            transition: all .5s ease 0s;

            h4 {
                color: navajowhite;

            }

            &:hover {
                color: #da291c;

                h4 {
                    color: #da291c;
                }
            }
        }
    }

}

.section-box-tension {
    //font-family: 'Rubik', sans-serif !important;
    //background: $ps-color;
    height: fit-content;
    padding: 50px 0px 100px 0px;

    @include breakpoint(small only) {
        padding: 20px 0px 20px 0px;
    }



    h2,
    p {
        //text-align: center;
    }

    h2 {
        color: scale-color($ps-color, $lightness: 35%);

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        font-weight: 300;
        color: white;
        font-weight: 100;
        //font-size: 1.1rem;
        margin-bottom: 50px;
    }

    h4 {
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        margin: 0px;
    }

    p.info {
        font-size: 0.9rem;
        text-align: left;
    }

    .pic {
        position: relative;
        //left: 50%;
        //top: 0;
        width: 100%;
        height: fit-content;
        //margin-left: -960px;
        //height: 960px;
        box-shadow: inset 0px 0px 35px 0px rgb(234, 234, 244);

        img {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.9s ease 0s;
            max-width: 100%;
            height: auto;
            vertical-align: top;
            -ms-interpolation-mode: bicubic;
            border: 0;
        }

        img.cur {
            opacity: 1;
            visibility: visible;
            //box-shadow: inset 0px 0px 35px 0px rgb(234, 234, 244);
        }
    }

    .tit {
        text-align: center;
        margin: 0px auto;
        //padding: 20px;
        color: #fff;
        width: 100%;
        position: absolute;
        top: 0;
        //left: 0;
        z-index: 11;

        @include breakpoint(small only) {
            text-align: right;
        }

        .light {
            margin-top: 10px;
            //position:relative;
            //right: 0%;
            text-align: center;
            cursor: pointer;

            @include breakpoint(small only) {
                text-align: right;
                margin-right: 10px;
            }

            .light_con {
                position: relative;
                display: inline-block;
                //display: inline;
                //zoom: 1;
                height: 28px;
            }

            .light_line {
                width: 92px;
                height: 28px;
                background: url(../powersports-img/ori-name/close_light.png);
                margin: 0 auto;
                display: inline-block;
                *display: inline;
                *zoom: 1;
                transition: all 0.5s ease 0s;
            }

            .light_ball {
                position: absolute;
                width: 60px;
                height: 60px;
                top: 50%;
                margin-top: -30px;
                right: 100%;
                margin-right: -40px;
                background: url(../powersports-img/ori-name/ball_light.png);
                transition: all 0.5s ease 0s;
            }

        }

        .light.open {
            .light_line {
                background: url(../powersports-img/ori-name/open_light.png);
            }

            .light_ball {
                right: 0;
                margin-right: -20px;
            }
        }

    }

    .btm {
        position: relative;
        left: 0;
        right: 0;
        //bottom: 120px;

        @include breakpoint(small only) {
            padding-top: 210px;
        }

        .item {
            position: relative;
            color: #fff;
            cursor: pointer;
            transition: all .5s ease 0s;

            h4 {
                color: scale-color($ps-color, $lightness: 65%); //navajowhite;

            }

            &:hover {
                color: scale-color($ps-color, $lightness: 85%); //#da291c;

                h4,
                p {
                    color: scale-color($ps-color, $lightness: 85%); //#da291c;
                }
            }
        }
    }

}
