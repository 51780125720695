//dedicated to powersports temporary index page

$hero-full-screen-bg: url('../../assets/powersports-img/desktop01-tsnarl.jpg') center center no-repeat;
$hero-full-mobile-screen-bg: url('../../assets/powersports-img/vert01-tsnarl.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $white;

.hero-full-screen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: $hero-full-screen-bg;
    background-size: $hero-full-screen-bg-size;
    margin: 0px;

    @include breakpoint(small only) {
        background: $hero-full-mobile-screen-bg;
        background-size: $hero-full-screen-bg-size;
        margin: 0px;
    }

    .middle-content-section {
        text-align: center;
        color: $hero-full-screen-text-color;

        h1 {
            font-size: 2.25em;
            margin-bottom: 10px;

            @include breakpoint(small only) {
                font-size: 1.25em;
                margin-bottom: 10px;
            }

        }

        p {
            margin-bottom: 30px;

            @include breakpoint(small only) {
                font-size: .75em;
                margin: 0 10px 30px 10px;
            }

            .red {
                color: darkred;
            }
        }
    }

    .top-content-section {
        width: 100%;
        margin-top: 0px;
    }

    .bottom-content-section {
        padding: 1rem;

        svg {
            height: rem-calc(60);
            width: rem-calc(60);
            fill: $hero-full-screen-text-color;
        }
    }

    // optional demo styles
    .top-bar {
        background: transparent;
        margin-top: 0px;

        .menu {
            background: transparent;
        }

        .menu-text {
            color: $hero-full-screen-text-color;
        }

        .menu li {
            display: flex;
            align-items: center;
        }

        .menu a {
            color: $hero-full-screen-text-color;
            font-weight: bold;
        }
    }
}

.reveal {
    background-color: rgba(255, 255, 255, 0.7);
    border: 4px solid $ps-color;
    border-radius: 20px;
}

#brochures-01 {
    h3 {
        text-transform: uppercase;
        font-weight: bold;
    }

    .media-object {
        .media-object-section {
            img {
                border-radius: 50%;
                border: 2px solid saddlebrown;
            }

            a {
                color: brown;

                &:hover,
                &:focus {
                    color: darkslategray;
                }
            }
        }
    }

}
