.section-box-engine {
    background: transparentize($ps-color, 1);
    //background: scale-color($ps-color, $lightness: 5%);
    height: fit-content;
    padding: 50px 0px 100px 0px;

    h2 {
        color: scale-color($ps-color, $lightness: 95%); //$ps-color;

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 85%);
        font-weight: 100;
    }

}

.engine-headbox,
.engine-headboxy {
    width: 100%;
    height: fit-content; //40vh;
    position: relative;

}

.headboxy {
    @include breakpoint(small only) {
        display: flex;
        flex-direction: column;
    }
}

.section-box-engine .engine-headbox {
    //background: rgba(0, 0, 0, 0.5);

    //display: flex;
    //flex-direction: row;
    .item-x {
        display: grid;
        grid-column-gap: 20px;
        grid-template-areas: 'box1 box2 box3 box4';
        grid-template-columns: 25% 25% 25% 25%;
        //width: 50%;
        //margin: 0 auto;
        //justify-items: center;

        @include breakpoint(small only) {
            display: flex;
            flex-direction: column;
            height: fit-content;

        }

    }

}

.section-box-engine .engine-headbox .item,
.engine-headboxy .item {
    //width: 31.33%;
    //float: left;
    cursor: pointer;
    margin: 50px 0px;

    @include breakpoint(small only) {
        margin: 20px 0px;
    }
}

.engine-headboxy .item2,
.engine-headboxy .item3 {
    display: grid;
    grid-column-gap: 20px;
    grid-template-areas: 'box1 box2';
    grid-template-columns: 50% 50%;

    @include breakpoint(small only) {
        display: flex;
        flex-direction: column;
    }
}

.section-box-engine .engine-headboxy .item2 {
    //margin: 0 3%;
}

.section-box-engine .engine-headbox .item .img,
.engine-headboxy .item .img {
    position: relative;
    overflow: hidden;
    grid-area: box2;

    @include breakpoint(small only) {
        //grid-area: box1;
        margin-bottom: 20px;
    }
}

.section-box-engine .engine-headboxy .item .img2 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -moz-transition: all .8s ease 0s;
    -ms-transition: all .8s ease 0s;
    -o-transition: all .8s ease 0s;
    -webkit-transition: all .8s ease 0s;
    transition: all .8s ease 0s;
}

.section-box-engine .engine-headboxy .item:hover .img2 {
    opacity: 1;
    visibility: visible;
}

.section-box-engine .engine-headbox .item1 .start,
.section-box-engine .engine-headbox .item1 .stop {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    margin-left: -132px;
    margin-top: -132px;
    opacity: 0;
    visibility: hidden;
    -moz-transition: all .8s ease 0s;
    -ms-transition: all .8s ease 0s;
    -o-transition: all .8s ease 0s;
    -webkit-transition: all .8s ease 0s;
    transition: all .8s ease 0s;
}

.section-box-engine .engine-headbox .item1.mp3_start .start {
    opacity: 1;
    visibility: visible;
}

.section-box-engine .engine-headbox .item1.mp3_stop .stop {
    opacity: 1;
    visibility: visible;
}

.section-box-engine .engine-headbox .item .txt {
    //margin: 43px 0 113px;
    padding-left: 22px;
    //padding-right: 48px;
    border-left: 3px solid #da291c;
    color: #fff;
    //width: 50%;
    grid-area: box3;

    @include breakpoint(small only) {
        //grid-area: box2;
        padding-left: 0px;
        padding-top: 20px;
        //padding-right: 48px;
        border-left: 0px; //solid #da291c;
        border-top: 3px solid #da291c;

    }


}

.section-box-engine .engine-headboxy .item .txt {
    //margin: 43px 0 113px;
    padding-left: 22px;
    //padding-right: 48px;
    border-right: 3px solid #da291c;
    color: #fff;
    //width: 50%;
    //grid-area: box3;

    @include breakpoint(small only) {
        //grid-area: box2;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 20px;
        //padding-right: 48px;
        border-right: 0px; //solid #da291c;
        border-top: 3px solid #da291c;

    }


}


.section-box-engine .engine-headbox .item .txt .t1,
.engine-headboxy .item .txt .t1 {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 500;
    margin-bottom: 10px;
    color: white;
}

.section-box-engine .engine-headbox .item .txt .d,
.engine-headboxy .item .txt .d {
    font-size: .95rem;
    line-height: 140%;
    font-weight: 100;
    color: white;
    //line-height: 20px;
    //min-height: 60px;
}
