//--- Main page section 3 styling

.boxbg1 {
    //background-color: adjust-hue($ps-color, 90);
    background-color: mix($ps-color, white, 30);
    padding: 20px 0px 30px 0px;
    //position: relative;

    hr {
        border-top: 10px dotted $ps-color;
        //border-radius: 5px;
    }

    h4 {
        font-weight: bold;
        color: $ps-color;
        text-transform: uppercase;
        text-align: center;
    }

    ul {
        padding-left: 20px;
    }

}

.app-notice {
    position: relative;
    height: 100%;

    @include breakpoint(small only) {
        height: 40vh;
        margin-bottom: 30px;
    }

    .resize-a {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 45%;
        height: auto;
        z-index: 0;

        @include breakpoint(small only) {
            width: auto;
            height: 40vh;
        }

    }

    .resize-b {
        width: 90%;
        height: auto;
        position: absolute;
        bottom: 0px;
        z-index: 1;

        @include breakpoint(small only) {
            width: 80%;
        }

    }
}

.scanbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
        background-color: $ps-color;
        border-radius: 20px;
        padding: 10px;
        color: mix($ps-color, white, 30);
        text-align: center;
        font-size: 0.9em;
        margin-bottom: 20px;
    }

    .resize-c {
        width: 35%;
        height: auto;

        @include breakpoint(small only) {
            width: 45%;
            height: auto;
        }
    }
}

.subbox {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;

    .sub-subbox {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
