//dedicated to powersports temporary index page

$hero-full-screen-bg: url('../../assets/powersports-img/main-pg-hero.jpg') center center no-repeat;
$hero-full-mobile-screen-bg: url('../../assets/powersports-img/vert01-main-pg-hero.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $white;

.scroll-run {
    position: relative;
    top: 100vh;
    box-shadow: 0px -5px 15px 5px rgba(27, 23, 29, 0.74);
}

.boxbg2 {
    //background-color: adjust-hue($ps-color, 90);
    background-color: mix($ps-color, white, 50);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //position: relative;
    //top: 100vh;

}

.main-pg-hero-full-screen {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    background: $hero-full-screen-bg;
    background-size: $hero-full-screen-bg-size;
    margin: 0px;
    position: fixed;

    @include breakpoint(small only) {
        background: $hero-full-mobile-screen-bg;
        background-size: $hero-full-screen-bg-size;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 80px;
        margin: 0px;

        i {
            color: aliceblue;
            font-size: 3em;
            animation-name: bounce-down;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    .card-frame {
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    .flexy {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;


        p {
            color: white;
            margin-bottom: 3rem;
        }
    }

    @keyframes bounce-down {
        from {
            transform: translateY(10px)
        }

        to {
            transform: translateY(0px)
        }
    }

    .middle-content-section {
        text-align: center;
        color: $hero-full-screen-text-color;

        h1 {
            font-size: 2.25em;
            margin-bottom: 10px;

            @include breakpoint(small only) {
                font-size: 1.25em;
                margin-bottom: 10px;
            }

        }

        p {
            margin-bottom: 30px;

            @include breakpoint(small only) {
                font-size: .75em;
                margin: 0 10px 30px 10px;
            }

            .red {
                color: darkred;
            }
        }
    }

    .top-content-section {
        width: 100%;
        margin-top: 0px;
    }

    .bottom-content-section {
        padding: 1rem;

        svg {
            height: rem-calc(60);
            width: rem-calc(60);
            fill: $hero-full-screen-text-color;
        }
    }

    // optional demo styles
    .top-bar {
        background: transparent;
        margin-top: 0px;

        .menu {
            background: transparent;
        }

        .menu-text {
            color: $hero-full-screen-text-color;
        }

        .menu li {
            display: flex;
            align-items: center;
        }

        .menu a {
            color: $hero-full-screen-text-color;
            font-weight: bold;
        }
    }
}

//THE MAIN PAGE CARD STYLING

// card anime
@keyframes down-top {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1.0;
    }
}

@keyframes top-down {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1.0;
    }
}

.anime-up {
    animation-name: down-top;
    animation-duration: 3s;
}

.anime-down {
    animation-name: top-down;
    animation-duration: 3s;

}

.card {
    width: 400px;
    background-color: transparentize(antiquewhite, .3);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 5px 1px $ps-color;


    @include breakpoint(small only) {
        width: 95vw;
        margin-bottom: 20px;
    }

    img.card-img {
        border-radius: 10px;
        border: 2px solid white;
    }
}

.card-info {
    padding: 1rem;
    display: grid;
    grid-template-rows: 1fr 2.5rem;
    grid-template-columns: 2fr 2fr;

    .button {
        text-transform: uppercase;
    }
}

.card-title {
    font-size: 1.4rem;
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    @include breakpoint(small only) {
        font-size: 1.25rem;
    }

    a {
        color: $ps-color;

        &:hover {
            //color: fade-out($ps-color, 0.7);
            color: $anchor-color-hover;
        }
    }
}

//.card-icon {
//    grid-row: 1 / 2;
//    grid-column: 2 / 3;
//    justify-self: right;
//    width: 30px;
//    height: 30px;
//    background-color: LightBlue;
//    border-radius: 100%;
//    display: grid;
//    justify-items: center;
//    align-items: center;
//}

.card-author {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    align-self: bottom;
    font-size: .9rem;

    @include breakpoint(small only) {
        font-size: .85rem;
    }
}

.card-stats {
    align-self: bottom;
    justify-self: right;

}
