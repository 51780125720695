//---sample code to only capitalize first letter
//&::first-letter {
//  text-transform: capitalize;
//}
//

//  My Custom Settings for Segway Powersports app.scss
//  -----------------------------------------------
//official site color
//$test-color: #030929; //#2c3840;
//$ps-color: #290903; placed at _setting already 
//$topbar-tagline-color: lighten($ps-color, 65%);
$topbar-tagline-color: scale-color($ps-color, $lightness: 90%);


// styling for psports-menu.html
@import '../hamburger-mixin';

//segway-menu topbar menu styling ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.topbar-fixed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100; //100;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    background: $ps-color;
    padding: 10px 15px;


    //adjusting flex direction on small screen 
    @media print,
    screen and (max-width: 40em) {
        flex-direction: column;
        align-items: stretch;
        padding: 0px auto;
    }

    .topbar-responsive-logo {
        font-size: 1.25rem;

        @media print,
        screen and (max-width: 40em) {
            font-size: 1rem;
        }

    }

    //    a {
    //        color: scale-color($ps-color, $lightness: 90%);
    //    }

    .title-bar {
        justify-content: space-between;
        flex-direction: row-reverse;
        background: $ps-color;

        p.nospan {
            font-size: .8rem;
        }

    }

    p.top-cta {
        color: $anchor-color;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 8px;
        margin-right: 8px;
        margin-left: 18px;
        letter-spacing: .25rem;

    }

}


//the default styling for hamburger mixin
#my-menu-icon,
.my-menu-icon {
    @include hamburger($color: white, $anim: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.33s);

}

//the solid background styling for hamburger mixin
// use it by referring to the given name ID and label tag used in the segway-menu.html code
#toggle-solid-background {
    @include hamburger($background: lighten($ps-color, 8%), $width: 1rem, $thickness: 3px, $gap: 4px, $padding: 12px, $color: white, $radius_background: 50%);
}


#my-menu {
    background: $ps-color;
    margin-top: 7px;
    width: 50wv;

    .first-sub {
        //background: scale-color($ps-color, $lightness: 80%);
        //background: fade-out($ps-color, .8);
        background: lighten($ps-color, 85%);
        border-radius: 8px;

        a {
            color: scale-color($ps-color, $lightness: -90%);
            padding-left: 30px;

            &:hover {
                background: scale-color(grey, $lightness: 90%);
                border-radius: 8px;
            }

        }

    }

    //in small screen the container bg should be 
    @media print,
    screen and (max-width: 40em) {
        //background: scale-color($ps-color, $lightness: 80%);
        background: lighten($ps-color, 85%);
        width: 100%;

        a {
            color: scale-color($ps-color, $lightness: -90%);

            &:hover {
                background: scale-color(grey, $lightness: 90%);
            }

        }

        ul.submenu > li {
            padding-left: 15px;
        }

    }

}
