.flexy-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint(small only) {
        margin-top: 30px;
    }
}

/* .section-box-qr .wave{position:relative; z-index:1;} */

.section-box-qr {
    position: relative;
    background: white;
    //background: scale-color($ps-color, $lightness: 100%);
    height: fit-content;
    padding: 50px 0px 100px 0px;

    h2 {
        color: $ps-color;

        @include breakpoint(small only) {
            font-size: 1.5rem;
        }
    }

    p {
        color: scale-color($ps-color, $lightness: 5%);
        font-weight: 100;
    }

}

.section-box-qr .wave {
    position: absolute;
    left: 25%;
    bottom: 0px;
    width: 50%;
    z-index: 0;

    @include breakpoint(small only) {
        position: relative;
        top: 100%;
    }

}

.section-box-qr .wave img {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.section-box-qr .wave3 {
    -webkit-animation: proWave 2s linear infinite 0.8s;
    animation: proWave 2s linear infinite 0.8s;
}

.section-box-qr .wave2 {
    -webkit-animation: proWave 2s linear infinite 0.4s;
    animation: proWave 2s linear infinite 0.4s;
}

.section-box-qr .wave1 {
    -webkit-animation: proWave 2s linear infinite;
    animation: proWave 2s linear infinite;
}

@-webkit-keyframes proWave {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    50% {
        opacity: 0.4;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2.4);
        transform: scale(2.4);
    }
}

@keyframes proWave {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
    }

    50% {
        opacity: 0.4;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2.4);
        transform: scale(2.4);
    }
}


.section-box-qr .content {
    position: relative;
    z-index: 2;
    padding-top: 200px;

    @include breakpoint(small only) {
        padding-top: 50px;
    }
}

.section-box-qr .content .left {
    width: 100%;
    position: relative;
    //max-width: 780px;
}

.section-box-qr .content .left .app {
    position: absolute;
    right: 0;
    bottom: 30px;
    z-index: 0;
    width: 37%;
    height: 100%;
}

.section-box-qr .content .left .app img {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

.section-box-qr .content .left .app img.cur {
    visibility: visible;
    opacity: 1;
    //box-shadow: inset 0px 0px 35px 0px rgb(234, 234, 244);
}

.section-box-qr .content .left .pro {
    position: relative;
    z-index: 1;
    width: 80%; //84%;
}


.section-box-qr .right {
    position: relative;
    width: 100%;
}

.section-box-qr .right .top .item {
    display: none;
    //color: white; //#000;
}

.section-box-qr .right .top .item.cur {
    display: block;
}

.section-box-qr .right .t {
    font-size: 1.7rem;

    @include breakpoint(small only) {
        font-size: 1.15rem;
    }
}

.section-box-qr .right .desc {
    font-size: 1rem;
    font-weight: 300;
    line-height: 150%;
    margin: 20px 0 40px;
    min-height: 44px;

    @include breakpoint(small only) {
        margin: 10px 0 10px;
    }
}

.section-box-qr .right ul {
    overflow: hidden;
    padding-left: 0;
    list-style-type: none;
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;

    @include breakpoint(small only) {
        margin-left: .8rem;
        grid-row-gap: 5px;
    }
}

.section-box-qr .right ul li {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 100;
    color: scale-color($ps-color, $lightness: 15%);
    //float: left;
    width: fit-content;
    position: relative;
    padding-left: 8%;

    @include breakpoint(small only) {
        font-size: .9rem;
    }
}

.section-box-qr .right ul li span {
    //display: inline-block;
    //*display: inline;
    //*zoom: 1;
    //position: relative;
    padding-bottom: 5px;
}

.section-box-qr .right ul li span:after {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    height: 1px;
    bottom: 0;
    background: scale-color($ps-color, $lightness: 15%); //#da291c;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

.section-box-qr .right ul li.cur {
    color: $ps-color; //#da291c;
    font-weight: 500;
}

.section-box-qr .right ul li.cur span:after {
    right: 0;
}

.section-box-qr .right ul li:after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: scale-color($ps-color, $lightness: 15%); //#e2b7b7; //#333;
    position: absolute;
    left: 0;
    top: 40%;
    //margin-top: -6px;

    @include breakpoint(small only) {
        top: 30%;
    }
}

.section-box-qr .right ul li.cur:after {
    background: scale-color($ps-color, $lightness: 15%); //#da291c;
    width: 9px;
    height: 9px;
}


.section-box-qr .right .search {
    color: white;
    font-size: .9rem;
    margin-top: 50px auto;
    //border: 1px solid #da291c;
    border-radius: 15px;
    background: $ps-color; //#da291c;
    padding: 10px;
    display: inline-block;
    //*display: inline;
    //*zoom: 1;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.section-box-qr .right .search i {
    margin-left: 15px;
}

.section-box-qr .right .btm {
    margin: 40px 0px 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include breakpoint(small only) {
        margin: 10px 0px 10px 0px;
        flex-direction: column;
        align-items: center;
    }

}

.section-box-qr .right .qrcode {
    margin-right: 55px;
    width: 150px;

    @include breakpoint(small only) {
        //display:flex;
        //flex-direction: column;
        //align-items: center;
        margin: 0 auto;
    }
}

/*.section-box-qr .right .btm .dl {padding-top: 28px; }*/
.section-box-qr .right .btm a {
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 14px;
    //font-family: BentonSans;
    transition: all 0.3s ease;
    text-decoration: none;
    outline: 0;
}

.section-box-qr .right .btm a i {
    font-size: 22px;
    margin-right: 5px;
}

.section-box-qr .right .qrcode img {
    display: none;
}

.section-box-qr .right .qrcode img.cur {
    display: block;
}

.common-link {
    display: block;
    width: 200px;
    line-height: 42px;
    color: #da291c;
    border: 1px solid #da291c;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

.common-link:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    background: #da291c;
    -moz-transition: all .8s ease 0s;
    -ms-transition: all .8s ease 0s;
    -o-transition: all .8s ease 0s;
    -webkit-transition: all .8s ease 0s;
    transition: all .8s ease 0s;
    z-index: 0;
}

.common-link span {
    position: relative;
    z-index: 1;
}

.common-link:hover,
.common-link.solid {
    color: #fff;
}

.common-link:hover:after,
.common-link.solid:after {
    right: 0;
}

::selection {
    color: #fff;
    background: #da291c;
}
