//the styling for segway-menu.html
//++++++++++++++++++++++++++++++++++

$topbar-responsive-bg: $ps-color;
$topbar-tagline-color: #e4e1ea;
$topbar-responsive-animation-type: fade-in; // or use slide-down or none

//.top-bar-right > ul.menu > li > a {
//color: white;
//}

.topbar-fixed {
    z-index: 100;
}

div.logobox {
    width: 25px;
    height: 25px;
    //background-color: $topbar-tagline-color;
    display: inline-block;
    margin: 0;
}

//this is to modify the color of a tag in the menu of a dropdown menu
.dropdown.menu > li.is-active > a {
    color: lighten($topbar-responsive-bg, 60%);
}

//The fixed topbar menu case that make it fixed when page is scrolled
//.sticky {
//    width: 100%;
//    position: fixed;
//    top: 0;
//    z-index: 100;
//    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
//    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
//    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
//}

p.nospan {
    color: $topbar-tagline-color;
    font-size: 1rem;
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: inline-block;
    margin-left: 4px;
}



.topbar-responsive {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $topbar-responsive-bg;
    padding: 1rem 1.5rem;

    .top-bar-title {
        margin: 0;
    }

    .topbar-responsive-logo {
        color: $white;
        //vertical-align: middle;
        font-size: 2rem;
    }

    .top-bar-right {
        display: flex;
        flex-direction: row;
        width: 50vw;
        justify-content: flex-end;
        align-items: center;

    }

    .top-cta {
        color: $topbar-tagline-color;
        display: inline-block;
        margin-bottom: 8px;
        margin-right: 8px;
        margin-left: 18px;
        letter-spacing: .25rem;


        a {
            color: scale-color($topbar-tagline-color, $lightness: -30%);
        }

    }

    @media screen and (max-width: 40em) {
        .topbar-responsive-logo {
            font-size: 1.15rem;
        }

        p.nospan {
            color: #e4e1ea;
            font-size: 0.6rem;
            padding-bottom: 0px;
            margin-bottom: 0px;
            //display: inline-block;
            //margin-left: 10px;
        }

        .menu.simple li + li {
            margin-left: 0rem;
        }

        .top-small-hide {
            display: none;
        }
    }


    .dropdown.menu,
    .menu {
        background: $topbar-responsive-bg;

        li:last-of-type {
            margin-right: 0;
        }

        .submenu {
            //background-color: scale-color($sm-color, $lightness: 40%);
            //background: saturate($sm-color, 50%);
            background: transparentize($sm-color, .3);
            border-radius: 0px 0px 0px 10px;
            position: absolute;
            left: 50px;

        }

        a {
            color: $white;
            transition: color 0.15s ease-in;

            &:active,
            &:focus,
            &:focus-within,
            &:hover {
                color: lighten($topbar-responsive-bg, 60%);
            }


            @media screen and (max-width: 40em) {
                padding: 0.875rem 0;
            }
        }

        .topbar-responsive-button {
            color: $white;
            border-color: $white;
            border-radius: 5000px;
            transition: color 0.15s ease-in, border-color 0.15s ease-in;

            &:hover {
                color: lighten($topbar-responsive-bg, 60%);
                border-color: lighten($topbar-responsive-bg, 60%);
            }

            @media screen and (max-width: 40em) {
                width: 100%;
                margin: 0.875rem 0;
            }
        }
    }

    @media screen and (max-width: 40em) {
        padding: 0.75rem;

        .top-bar-title {
            position: relative;
            width: 100%;
            flex-direction: row-reverse;
            justify-content: flex-start;

            a > h1 {
                color: white;
                display: inline-block;
            }

            span {
                position: absolute;
                right: 0;
                border: 1px solid $white;
                border-radius: 5px;
                padding: 0.25rem 0.45rem;
                top: 40%;
                transform: translateY(-50%);

                .menu-icon {
                    margin-bottom: 4px;
                }
            }



        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@include breakpoint(small only) {
    .topbar-responsive-links {
        animation-fill-mode: both;
        animation-duration: 0.5s;
        width: 100%;

        @if $topbar-responsive-animation-type==slide-down {
            animation: slideDown 1s ease-in;
        }

        @else if $topbar-responsive-animation-type==fade-in {
            animation: fadeIn 1s ease-in;
        }

        @else {
            animation: none;
        }
    }
}

@include breakpoint(large) {
    .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
        top: 100%;
        right: -120px;
        /* left: auto; */
    }
}

@include breakpoint(medium down) {
    .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
        top: 80%;
        right: -140%;
        background: desaturate($sm-color, 10%);
        border-radius: 0px 0px 10px 10px;
        /* left: auto; */
    }

    .topbar-responsive .top-bar-right {
        display: flex;
        flex-direction: row;
        width: 100vw;
        justify-content: center;
        align-items: center;

        .menu.vertical {
            text-align: center;

            li.opens-left > a::after {
                display: block;
                width: 0;
                height: 0;
                //border: inset 5px;
                content: '';
                border-left: .30rem solid transparent;
                border-right: .30rem solid transparent;
                border-top: .30rem solid black; //white;
                //border-color: transparent white transparent transparent;
                right: auto;
                left: 60px;
            }


        }

    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a {
        padding-right: 0;
    }

    .topbar-responsive .dropdown.menu a,
    .topbar-responsive .menu a {
        padding: 0.6rem 0;
    }

    //modify the color of dropdown arrow in mobile screen
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
        display: block;
        width: 0;
        height: 0;
        border: inset 6px;
        content: '';
        border-bottom-width: 0;
        border-top-style: solid;
        border-color: $sm-color transparent transparent;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        right: 1rem;
    }




}


.hide {
    display: none;
}
